import {mapActions, mapGetters, mapMutations} from "vuex";
import {snakeCase} from 'lodash'
export default {
  name: "user-info",
  data(){
    return{
      payload:{
        professionalId:''
      },
    }
  },
  components: {
  },
  computed:{
    ...mapGetters({
      cosmetologistId: 'pages/page',
      user: 'profile/user'
    })
  },
  watch:{
  },
  created() {
    this.fetchCosmetologistPage('cosmetologist-id')
  },
  methods:{
    ...mapActions({
      fetchCosmetologistPage:'pages/GET_PAGE',
      updateCosmetologId:'profile/UPDATE_USER_DATA',
      fetchUser:'profile/FETCH_USER_DATA',
    }),
    ...mapMutations({
      changeAnotherCosmetologPopup:`popups/CHANGE_COSMETOLOG_POPUP`,
    }),
    submit(){
      let obj = {}
      for (let i in this.payload) {
        obj[snakeCase(i)] = this.payload[i]
      }
      this.updateCosmetologId(obj).then(()=>{
        this.fetchUser()
        this.$toasted.success(this.$t('successAddCosmetolog'))
      }).catch(()=>{
        this.$toasted.error('Не вірний код косметолога')
      })
    }
  }
}